import type { FC } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Text } from 'components'
import { CommitmentBackground } from 'assets'
import TypedDescription from '../AboutUs/TypedDescription'

import styles from './Commitment.module.scss'
import { Slide } from 'react-awesome-reveal'

const Commitment: FC = () => {
  const navigate = useNavigate()

  const navigateToContactUs = () => {
    navigate('/contact-us')
  }

  return (
    <div className='bg_dark'>
      <div className={classNames(styles.wrapper, 'container')}>
        <Slide direction={'left'} className={styles.wrapper__block_img} triggerOnce>
          <img src={CommitmentBackground} alt='CommitmentBackground' />
        </Slide>
        <div className={styles.wrapper__block}>
          <div className={styles.wrapper__block_info}>
            <Slide direction={'right'} triggerOnce>
              <Text text='whyChooseUs' className={styles.wrapper__block_info_title} />
            </Slide>

            <Slide direction={'right'} delay={1} triggerOnce>
              <Text text='whyChooseUsSubtitle' className={styles.wrapper__block_info_text} />
            </Slide>
            <TypedDescription key={'commitment'} text={'whyChooseUsText'} />
            <button className={styles.wrapper__block_info_btn} onClick={navigateToContactUs}>
              <Text text={'contactUs'} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Commitment
