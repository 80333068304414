import { FC, useEffect } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import { Footer } from 'components'
import { RoutesWrapper } from 'libraries/router'

import Header from '../Header'
import styles from './PageLayout.module.scss'

const PageLayout: FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <main>
      <Header />
      <div className={classNames(styles.top__spacing, 'bg_dark')} />

      <section>
        <RoutesWrapper />
      </section>

      <Footer />
    </main>
  )
}

export default PageLayout
