import { FC, Fragment, useState } from 'react'
import classNames from 'classnames'
import { useWindowSize } from '@uidotdev/usehooks'
import { Link, useLocation } from 'react-router-dom'

import { SelectLanguage, Text } from 'components'
import routesList from 'libraries/router/routes'
import { Logo, BurgerMenuIcon, CloseIcon } from 'assets'

import styles from './Header.module.scss'

const Header: FC = () => {
  const location = useLocation()
  const displaySize = useWindowSize()

  const [showSideBar, setShowSideBar] = useState(false)

  return (
    <>
      <header className={classNames(styles.header, 'bg_dark')}>
        <div className={classNames(styles.header__wrapper, 'container')}>
          <a href='/'>
            <img alt={'logo'} src={Logo} className={styles.page__logo} />
          </a>
          <ul className={styles.menu}>
            {routesList.map(item => (
              <Fragment key={item.title}>
                {item.title !== 'error' && (
                  <li
                    className={classNames(styles.menu__item, {
                      [styles.menu__item__active]: location.pathname === item.path,
                    })}
                  >
                    <Link to={item.path}>
                      <Text text={item.title} />
                    </Link>
                  </li>
                )}
              </Fragment>
            ))}
          </ul>

          {Number(displaySize.width) > 750 && <SelectLanguage />}

          <BurgerMenuIcon
            className={styles.burger__menu}
            onClick={() => {
              setShowSideBar(true)
            }}
          />
        </div>
      </header>

      <div
        role='button'
        className={`${styles.overlay} ${showSideBar ? styles.show : styles.hide}`}
        onClick={() => {
          setShowSideBar(false)
        }}
      />
      <div className={`${styles.sidebar} ${showSideBar ? styles.show : styles.hide}`}>
        <div className={styles.sidebar__header}>
          <SelectLanguage />

          <CloseIcon
            className={styles.sidebar__close}
            onClick={() => {
              setShowSideBar(false)
            }}
          />
        </div>
        <ul className={styles.menu}>
          {routesList.map(item => (
            <Fragment key={item.title}>
              {item.title !== 'error' && (
                <li
                  className={`${styles.menu__item} ${location.pathname === item.path ? styles.menu__item__active : ''}`}
                >
                  <Link
                    className={`${styles.menu__item} ${
                      location.pathname === item.path ? styles.menu__item__active : ''
                    }`}
                    to={item.path}
                    onClick={() => setShowSideBar(false)}
                  >
                    <Text text={item.title} />
                  </Link>
                </li>
              )}
            </Fragment>
          ))}
        </ul>
      </div>
    </>
  )
}

export default Header
