import { FC, memo, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useCountUp } from 'react-countup'
import { useInView } from 'react-intersection-observer'

import { Text } from 'components'

import { statisticList, TStatisticItem } from './index'

import styles from './StatisticList.module.scss'

interface IStatisticItemProps {
  index: number
  item: TStatisticItem
}

const StatisticItem: FC<IStatisticItemProps> = ({ item, index }) => {
  const countUpRef = useRef(null)
  const showAnimation = useRef(true)

  const { ref, inView } = useInView({
    threshold: 0,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formattingFn = useCallback((n: number) => n + (item?.suffix || ''), [])

  const { start } = useCountUp({
    start: 0,
    duration: 3,
    ref: countUpRef,
    end: item.number,
    formattingFn,
  })

  useEffect(() => {
    if (inView && showAnimation.current) {
      start()
      showAnimation.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <>
      <div
        ref={ref}
        className={classNames(styles.wrapper__statistics_item, {
          [styles.wrapper__statistics_item_last]: index === 0,
          [styles.wrapper__statistics_item_second]: index === 3,
        })}
      >
        <p className={styles.wrapper__statistics_item__number} ref={countUpRef} />

        <Text text={item.text} className={styles.wrapper__statistics_item_description} />
      </div>
      {index !== statisticList.length - 1 && <div className={styles.wrapper__statistics__vertical_line} />}
    </>
  )
}

export default memo(StatisticItem)
