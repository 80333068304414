import { useState, useEffect, useRef, type FC } from 'react'
import { Carousel } from 'antd'
import classNames from 'classnames'
import { useWindowSize } from '@uidotdev/usehooks'

import { ArrowLeftIcon, ArrowRightIcon } from 'assets'

import { data } from './utils'
import styles from './Carousel.module.scss'
import { Text } from '../../index'

const App: FC = () => {
  const carouselContainerRef = useRef<HTMLDivElement>(null)
  const displaySize = useWindowSize()

  const [size, setSize] = useState<number>(0)

  useEffect(() => {
    if (carouselContainerRef?.current) {
      setSize(carouselContainerRef?.current?.clientHeight)
    }
  }, [displaySize?.width])

  return (
    <div className='bg_dark' style={{ position: 'relative', width: '100%' }}>
      <div style={{ height: `${size}px` }} />

      <div className={classNames('container', styles.container)} ref={carouselContainerRef}>
        <Carousel
          arrows
          infinite={true}
          dotPosition='bottom'
          prevArrow={<ArrowLeftIcon className={styles.arrow} />}
          nextArrow={<ArrowRightIcon className={styles.arrow} />}
        >
          {data.map((item, index) => (
            <div key={index}>
              <div className={styles.carousel__item}>
                <div className={styles.carousel__item__info__container}>
                  <Text text={item.title} className={styles.carousel__item__info__title} />

                  <Text text={item.description} className={styles.carousel__item__info__description} />
                </div>

                <div className={styles.carousel__item__image}>
                  <img alt='' className={styles.carousel__item__image_item} src={item.image} />
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default App
