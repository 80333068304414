import type { FC } from 'react'
import { ReactTyped } from 'react-typed'

import styles from './AboutUs.module.scss'
import { useTranslation } from 'react-i18next'

interface ITypedDescriptionProps {
  text: string
}

const TypedDescription: FC<ITypedDescriptionProps> = ({ text }) => {
  const { t } = useTranslation()

  return (
    <div>
      <ReactTyped
        typeSpeed={10}
        strings={[t(text)]}
        startWhenVisible
        showCursor={false}
        className={styles.wrapper__block_info_description}
      />
    </div>
  )
}
export default TypedDescription
