export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 320 },
    items: 1,
    slidesToSlide: 1,
  },
}

export const sliderImageUrl = [
  {
    title: 'Դարգեթ',
    url: 'https://proeng.am/assets/portfolio/0.jpg',
  },
  {
    title: 'ՌենդեռՖոռեսթ',
    url: 'https://proeng.am/assets/portfolio/1.jpg',
  },
  {
    title: 'Առանձնատուն',
    url: 'https://proeng.am/assets/portfolio/2.jpg',
  },
  {
    title: 'COAF',
    url: 'https://proeng.am/assets/portfolio/3.png',
  },
  {
    title: 'Ազատություն 26/1',
    url: 'https://proeng.am/assets/portfolio/4.jpg',
  },
]
