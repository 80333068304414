import { lazy } from 'react'

import { ERoutePaths } from './types'

import Home from 'pages/Home'
import AboutUs from 'pages/AboutUs'
import Services from 'pages/Services'
import Portfolio from 'pages/Portfolio'
import ContactUs from 'pages/ContactUs'

const Error = lazy(() => import('pages/Error'))

const routesList = [
  {
    element: Home,
    path: ERoutePaths.Home,
    title: 'home',
  },
  {
    element: Services,
    path: ERoutePaths.Services,
    title: 'services',
  },
  {
    element: Portfolio,
    path: ERoutePaths.Portfolio,
    title: 'portfolio',
  },
  {
    element: AboutUs,
    path: ERoutePaths.AboutUs,
    title: 'aboutUs',
  },
  {
    element: ContactUs,
    path: ERoutePaths.ContactUS,
    title: 'contactUs',
  },
  {
    element: Error,
    path: ERoutePaths.Error,
    title: 'error',
  },
]

export default routesList
