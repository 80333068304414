import type { FC } from 'react'
import classNames from 'classnames'
import Carousel from 'react-multi-carousel'

import { BriefcaseIcon } from 'assets'
import { SliderItem, Text } from 'components'

import { responsive, sliderImageUrl } from './utils'
import styles from './Activity.module.scss'

const Activity: FC = () => (
  <div className={styles.activityContainer}>
    <div className={classNames('container', styles.activity)}>
      <div className={styles.head}>
        <div className={styles.head__block}>
          <BriefcaseIcon className={styles.head__block_title_icon} />
          <p className={styles.head__block_title}>
            <Text text={'activity'} className={styles.head__block_title_services} tagName={'pre'} />
          </p>
        </div>
        <Text className={styles.head__description} text='activityText' />
        <ul className={styles.head__description__list}>
          <li>
            <Text className={styles.head__description__list__item} text='activityText1' />
          </li>
          <li>
            <Text className={styles.head__description__list__item} text='activityText2' />
          </li>
          <li>
            <Text className={styles.head__description__list__item} text='activityText3' />
          </li>
        </ul>
      </div>

      <div className={styles.carousel} style={{ width: '100%' }}>
        <Carousel
          autoPlay
          showDots
          infinite
          swipeable
          draggable
          responsive={responsive}
          removeArrowOnDeviceType={['mobile', 'tablet']}
          itemClass={styles.carousel__item}
          containerClass={styles.carousel__container}
        >
          {sliderImageUrl.map((item, index) => (
            <SliderItem
              key={index}
              src={item.url}
              alt='dea'
              title={item.title}
              date='November 2023'
              location='M4 highway'
              description='Lorem Ipsum is simply dummy text of the printing and typesetting printing and typesetting printing and typeset'
            />
          ))}
        </Carousel>
      </div>
    </div>
  </div>
)

export default Activity
