import { setCookie } from 'libraries/cookie'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ELanguage } from 'types'

const useLanguageTranslation = () => {
  const { i18n } = useTranslation()

  const onLanguageChangeCallback = useCallback(
    (language: string) => {
      i18n.changeLanguage(language)
      setCookie('i18next', language)
    },
    [i18n]
  )

  return {
    language: i18n.language as ELanguage,
    isEnglish: i18n.language === 'en',
    onLanguageChangeCallback,
  }
}

export default useLanguageTranslation
