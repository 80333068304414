import type { FC } from 'react'
import classNames from 'classnames'
import { Slide } from 'react-awesome-reveal'
import { useNavigate } from 'react-router-dom'

import { Text } from 'components'
import { HomeAbutUsImage } from 'assets'

import StatisticList from '../StatisticList'
import TypedDescription from './TypedDescription'
import styles from './AboutUs.module.scss'

const AboutUs: FC = () => {
  const navigate = useNavigate()

  const navigateToAbutUs = () => {
    navigate('/about-us')
  }

  return (
    <div className='bg_dark'>
      <div className={classNames('container', styles.wrapper)}>
        <StatisticList />
        <div className={styles.wrapper__block}>
          <div className={styles.wrapper__block_info}>
            <Slide triggerOnce>
              <Text text='aboutUs' className={styles.wrapper__block_info_title} />
            </Slide>
            <Slide triggerOnce delay={1}>
              <Text text='welcomeToKansolut' className={styles.wrapper__block_info_text} />
            </Slide>

            <TypedDescription text='aboutUsText' />

            <button className={styles.wrapper__block_info_btn} onClick={navigateToAbutUs}>
              <Text text={'moreAboutUs'} />
            </button>
          </div>

          <Slide direction={'right'} triggerOnce className={styles.wrapper__block_img}>
            <img src={HomeAbutUsImage} alt='HomeAbutUsImage' />
          </Slide>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
