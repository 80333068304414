import { FC, memo } from 'react'

import styles from './StatisticList.module.scss'
import StatisticItem from './Item'

export type TStatisticItem = {
  text: string
  number: number
  suffix?: string
}

export const statisticList: TStatisticItem[] = [
  {
    number: 10,
    suffix: '+',
    text: 'yearsOfExperience',
  },
  {
    number: 120,
    text: 'happyClients',
  },
  {
    number: 150,
    suffix: '+',
    text: 'projectsDone',
  },
  {
    number: 830451,
    text: 'squareMeter',
  },
]

const StatisticList: FC = () => (
  <div className={styles.wrapper__statistics}>
    {statisticList.map((item, index) => (
      <StatisticItem index={index} item={item} key={index} />
    ))}
  </div>
)

export default memo(StatisticList)
