import type { FC } from 'react'
import classNames from 'classnames'

import type { TBlockImageComponentProps } from './types'

import styles from './BlockImageComponent.module.scss'

const BlockImageComponent: FC<TBlockImageComponentProps> = ({ title, description, backgroundImage }) => (
  <div className={styles.container}>
    <div className={styles.container__item}>
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className={classNames('image_container', styles.wrapper)}
      >
        <h4 className={classNames(styles.wrapper__text, styles.wrapper__title)}>{title}</h4>
        <p className={classNames(styles.wrapper__text, styles.wrapper__description)}>{description}</p>
      </div>
    </div>
  </div>
)

export default BlockImageComponent
