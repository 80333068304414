import { type FC } from 'react'
import { Text } from 'components'
import { Fade } from 'react-awesome-reveal'

import styles from './ImageHeading.module.scss'

interface IImageHeadingProps {
  title: string
  image?: string
  subTitle: string
  description: string
}

const ImageHeading: FC<IImageHeadingProps> = ({ title, subTitle, description, image }) => (
  <div style={image ? { backgroundImage: `url(${image})` } : {}} className={styles.top_part}>
    <div className='container'>
      <div className={styles.top_part__overlay} />
      <Fade triggerOnce>
        <Text text={title} className={styles.top_part__title} />
      </Fade>
      <Fade triggerOnce delay={500}>
        <Text className={styles.top_part__sub_title} text={subTitle} />
      </Fade>
      <Fade triggerOnce delay={1000}>
        <Text className={styles.top_part__description} text={description} />
      </Fade>
    </div>
  </div>
)

export default ImageHeading
