import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'
import {
  Logo,
  Copyright,
  PhoneIcon,
  MessageIcon,

  // FooterLogoDark,

  /* FacebookIcon, InstagramIcon, TelegramIcons, */
} from 'assets'

import styles from './Footer.module.scss'

const Footer: FC = () => (
  <footer className={styles.container}>
    <div className={classNames(styles.footer, 'container')}>
      <div className={styles.footer__left}>
        <div className={styles.footer__left_top}>
          <Text text='information' className={styles.footer__title} />
          <Text text={'informationText'} className={styles.footer__description} />
        </div>
        <p className={styles.footer__left_copyright}>
          <Copyright /> www.proeng.am
        </p>
      </div>

      <div className={styles.footer__center}>
        <img src={Logo} alt='Full logo' className={styles.footer__center_img} />

        {/*<div className={styles.footer__center__socials}>*/}
        {/*  <a href='https://www.facebook.com/profile.php?id=100087786600544&mibextid=LQQJ4d' target={'_blank'}>*/}
        {/*    <FacebookIcon />*/}
        {/*  </a>*/}
        {/*  <a href='https://www.instagram.com/kansolut?igsh=OTB5enBreTRmY3V6' target={'_blank'}>*/}
        {/*    <InstagramIcon />*/}
        {/*  </a>*/}
        {/*  <a href='https://t.me/KANSolut' target={'_blank'}>*/}
        {/*    <TelegramIcons />*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>

      <div className={styles.footer__right}>
        <div className={styles.footer__right_top}>
          <Text text='contact' className={styles.footer__title} />

          <Text text={'contactText'} className={styles.footer__description} />
        </div>

        <div className={styles.footer__right_bottom}>
          <a href='mailto:info@proeng.am'>
            <p className={styles.footer__right_bottom_item}>
              <MessageIcon /> info@proeng.am
            </p>
          </a>
          <a href='tel:+37444144199'>
            <p className={styles.footer__right_bottom_item}>
              <PhoneIcon />
              +374 55 990 621
            </p>
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
