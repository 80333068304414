import type { FC } from 'react'

import styles from './SliderItem.module.scss'
import { LocationIcon, RightArrowIcon } from 'assets'

export type TSliderItemProps = {
  src: string
  alt: string
  date: string
  title: string
  location: string
  description: string
}

const SliderItem: FC<TSliderItemProps> = ({ src, alt, location, description, date, title }) => (
  <div className={styles.card}>
    <img className={styles.card__img} src={src} alt={alt} />

    <div className={styles.card_description}>
      <div className={styles.card_description_head}>
        <p className={styles.card_description_head_title}>{title}</p>

        {false && (
          <div className={styles.card_description_head_info}>
            <p className={styles.card_description_head_info_location}>
              <LocationIcon />
              {location}
            </p>
            <p className={styles.card_description_head_info_date}>{date}</p>
          </div>
        )}
      </div>

      {false && <p className={styles.card_description_text}>{description}</p>}

      {false && (
        <div className={styles.card_description_button}>
          <button className={styles.card_description_button_btn}>
            View all <RightArrowIcon />
          </button>
        </div>
      )}
    </div>
  </div>
)

export default SliderItem
