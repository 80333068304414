import { type FC, Fragment } from 'react'
import classNames from 'classnames'

import {
  AlarmService,
  AlarmServiceActive,
  AutomationService,
  AutomationServiceActive,
  CommunicationService,
  CommunicationServiceActive,
  CoolingService,
  CoolingServiceActive,
  ElectronicsService,
  ElectronicsServiceActive,
  FireProtectionService,
  FireProtectionServiceActive,
  GroundingService,
  GroundingServiceActive,
  IntercomService,
  IntercomServiceActive,
  IPService,
  IPServiceActive,
  LightingService,
  LightingServiceActive,
  PlumbingService,
  PlumbingServiceActive,
  PowerService,
  PowerServiceActive,
  SprinklerService,
  SprinklerServiceActive,
  TourniquetService,
  TourniquetServiceActive,
  VentilationService,
  VentilationServiceActive,
  VideoService,
  VideoServiceActive,
  WaterService,
  WaterServiceActive,
} from 'assets'
import { ServicesCard, Text } from 'components'
import type { TServicesCardProps } from 'components/ServicesCard/types'

import ImageHeading from '../ImageHeading'

import styles from './ServicesPage.module.scss'

const cardData: TServicesCardProps[] = [
  {
    name: 'Արտաքին և ներքին տաք և սառը ջրամատակարարման ցանցեր',
    description:
      'From concept to completion, we offer seamless design-build services that streamline the construction process. Our integrated approach ensures efficient communication, cost control, and timely project delivery.',
    Icon: WaterService,
    HoverIcon: WaterServiceActive,
  },

  {
    name: 'Արտաքին և ներքին ջրահեռացման ցանցեր',
    description:
      'As experienced general contractors, we manage every aspect of your project with meticulous attention to detail. From project planning and scheduling to subcontractor coordination and site management, we oversee the entire construction process.',
    Icon: PlumbingService,
    HoverIcon: PlumbingServiceActive,
  },

  {
    name: 'Ջրային հրդեհաշիջման համակարգեր',
    description:
      'Automatic fire suppression systems control and extinguish fires without human intervention. Examples of automatic systems include fire sprinkler system, gaseous fire suppression, and condensed aerosol fire suppression.',
    Icon: SprinklerService,
    HoverIcon: SprinklerServiceActive,
  },

  {
    name: 'Ջեռուցման, հովացման և սառնամատակարարման համակարգեր',
    description:
      'Our construction management services provide you with expert guidance and oversight throughout the construction lifecycle. We ensure that your project stays on budget and on schedule while maintaining the highest standards of quality.',
    Icon: CoolingService,
    HoverIcon: CoolingServiceActive,
  },

  {
    name: 'Օդափոխության և օդի լավորակման համակարգեր',
    description:
      "Specializing in commercial construction, we cater to a diverse range of industries including retail, hospitality, healthcare, and more. Whether you're building from the ground up or renovating an existing space, we deliver exceptional results tailored to your business needs.",
    Icon: VentilationService,
    HoverIcon: VentilationServiceActive,
  },

  {
    name: 'Հակածխային օդափոխության համակարգեր',
    description:
      'Committed to sustainability, we offer green building solutions that minimize environmental impact without compromising on design or functionality. From energy-efficient materials to eco-friendly construction practices, we help you build responsibly.' as string,
    Icon: FireProtectionService,
    HoverIcon: FireProtectionServiceActive,
  },
  {
    name: 'Բարձր և ցածր լարման ուժային էլեկտրամատակարարման համակարգեր',
    description:
      'Specializing in control and automation systems, our role encompasses designing and implementing advanced solutions that optimize industrial processes. Our expertise lies in developing customized control strategies, integrating hardware and software seamlessly, and driving innovation to meet evolving industry demands.',
    Icon: PowerService,
    HoverIcon: PowerServiceActive,
  },
  {
    name: 'Ներքին, արտաքին և գեղարվեստական լուսավորության համակարգեր',
    description:
      'Our focus in communication systems spans a comprehensive range of technologies crucial for modern connectivity. From local area networks (LANs) that facilitate internal data exchange within organizations, to the expansive reach of the Internet connecting people globally, we specialize in designing and managing robust, secure, and efficient networks.',
    Icon: LightingService,
    HoverIcon: LightingServiceActive,
  },
  {
    name: 'Հողանցման և շանթապաշտպանության համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: GroundingService,
    HoverIcon: GroundingServiceActive,
  },
  {
    name: 'Կառավարման և ավտոմատացման համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: AutomationService,
    HoverIcon: AutomationServiceActive,
  },
  {
    name: 'Լոկալ համակարգչային և կառուցվածքավորված մալուխային ցանցեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: CommunicationService,
    HoverIcon: CommunicationServiceActive,
  },
  {
    name: 'Ներքին և արտաքին տեսահսկման համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: VideoService,
    HoverIcon: VideoServiceActive,
  },
  {
    name: 'Թվային և IP հեռախոսակապ',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: IPService,
    HoverIcon: IPServiceActive,
  },
  {
    name: 'Կենտրոնացված TV և ռադիո համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: ElectronicsService,
    HoverIcon: ElectronicsServiceActive,
  },
  {
    name: 'Մուտքի հսկման և կառավարման համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: TourniquetService,
    HoverIcon: TourniquetServiceActive,
  },
  {
    name: 'Ձայնային և տեսաձայնային ինտերկոմ համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: IntercomService,
    HoverIcon: IntercomServiceActive,
  },
  {
    name: 'Հրդեհային ազդանշանման ինքնաշխատ համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: SprinklerService,
    HoverIcon: SprinklerServiceActive,
  },
  {
    name: 'Պահակային ազդանշանման ինքնաշխատ համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: AlarmService,
    HoverIcon: AlarmServiceActive,
  },
  {
    name: 'Տարհանման ազդարարման համակարգեր',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: AlarmService,
    HoverIcon: AlarmServiceActive,
  },
  {
    name: 'Հակահրդեհային և հակածխային ավտոմատացում',
    description:
      'Our role includes integrating high-resolution cameras, network video recorders (NVRs), and intelligent analytics software to provide real-time monitoring and proactive threat detection. By leveraging cutting-edge technologies such as AI-based video analytics and cloud-based storage solutions, we ensure robust surveillance capabilities that meet stringent security requirements.',
    Icon: FireProtectionService,
    HoverIcon: FireProtectionServiceActive,
  },
]

const ServicesPage: FC = () => (
  <Fragment>
    <ImageHeading
      title={'services'}
      subTitle={'Բարի գալուստ ՊՐՈԷՆՋ, որտեղ տեսլականը դառնում է իրականություն'}
      description={``}
    />
    <div className={classNames('container', styles.block)}>
      <Text
        className={styles.block__title}
        text='Մենք առաջարկում ենք հետևյալ համակարգերի նախագծման և մոնտաժման աշխատանքները'
      />

      <div className={styles.block__cards}>
        {cardData.map(({ name, description, Icon, HoverIcon }, index) => (
          <ServicesCard key={index} Icon={Icon} HoverIcon={HoverIcon} description={description} name={name} />
        ))}
      </div>
    </div>
  </Fragment>
)

export default ServicesPage
