import type { FC } from 'react'
import { Helmet } from 'react-helmet'

import type { HelmetProps } from './types'
import styles from './Helmet.module.scss'
import { useTranslation } from 'react-i18next'

const HelmetLayout: FC<HelmetProps> = ({ children, title }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{t(title)}</title>
      </Helmet>
      {children}
    </div>
  )
}

export default HelmetLayout
