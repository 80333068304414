import { FC, useCallback } from 'react'
import classNames from 'classnames'
import { Fade } from 'react-awesome-reveal'
import { useNavigate } from 'react-router-dom'

import { Text } from '../../index'

import {
  ServiceIcon,
  VideoSystemIcon,
  WaterSupplyIcon,
  PowerSupplyIcon,
  CoolingSystemIcon,
  GroundingSystemIcon,
  VintilationSystemIcon,
} from 'assets'

import styles from './Services.module.scss'

const servicesData = [
  {
    title: 'waterSupply',
    description: `We provide a wide range of services in the field of water supply and sanitation systems. Our team of experts will help you with the design, construction, and maintenance of water supply and sanitation systems.`,
    icon: <WaterSupplyIcon className={styles.service__item__icon} />,
  },
  {
    title: 'fireSupply',
    description: `Our team of experts will help you with the design, installation, and maintenance of fire and security alarm systems. We provide a wide range of services in the field of fire and security alarm systems.`,
    icon: <CoolingSystemIcon className={styles.service__item__icon} />,
  },
  {
    title: 'airCooling',
    description: `We provide a wide range of services in the field of cooling and air conditioning systems. Our team of experts will help you with the design, installation, and maintenance of cooling and air conditioning systems.`,
    icon: <VintilationSystemIcon className={styles.service__item__icon} />,
  },
  {
    title: 'voltage',
    description: `Our team of experts will help you with the design, installation, and maintenance of video surveillance systems. We provide a wide range of services in the field of video surveillance systems.`,
    icon: <PowerSupplyIcon className={styles.service__item__icon} />,
  },
  {
    title: 'video',
    description: `We provide a wide range of services in the field of grounding and bypass protection systems. Our team of experts will help you with the design, installation, and maintenance of grounding and bypass protection systems.`,
    icon: <VideoSystemIcon className={styles.service__item__icon} />,
  },
  {
    title: 'grounding',
    description: `Our team of experts will help you with the design, installation, and maintenance of communication systems. We provide a wide range of services in the field of communication systems.`,
    icon: <GroundingSystemIcon className={styles.service__item__icon} />,
  },
]

const Services: FC = () => {
  const navigate = useNavigate()

  const handleSeeAll = useCallback(() => {
    navigate('/services')
  }, [navigate])

  return (
    <div className={styles.servicesContainer}>
      <div className={classNames(styles.service__wrapper, 'container')}>
        <div className={styles.service__container}>
          <div className={styles.title__wrapper}>
            <p className={styles.service__title}>
              <Text text={'services'} className={styles.service__title_services} />
            </p>

            <ServiceIcon className={styles.service__title_icon} />
          </div>
          <div className={styles.service__item__wrapper}>
            {servicesData.map((item, index) => (
              <Fade key={index} className={styles.service__item} triggerOnce>
                <div>
                  <div className={styles.service__item__icon__container}>{item.icon}</div>
                  <div className={styles.service__item__info__container}>
                    <div className={styles.service__item__info__title}>
                      <Text text={item.title} />
                      {/*<div className={styles.service__item__info__description}>{item.description}</div>*/}
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
          <div className={styles.service__button__wrapper}>
            <button className={styles.service__button} onClick={handleSeeAll}>
              <Text text={'seeAll'} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
