import type { FC } from 'react'

import styles from './PortfolioCard.module.scss'

export type TPortfolioCardProps = {
  date: string
  title: string
  backgroundImage: string
}

const PortfolioCard: FC<TPortfolioCardProps> = ({ backgroundImage, title, date }) => (
  <div className={styles.wrapper}>
    <img src={backgroundImage} alt={title} className={styles.wrapper__image} />
    <p className={styles.wrapper__title}>{title}</p>
    <p className={styles.wrapper__date}>{date}</p>
  </div>
)

export default PortfolioCard
