import i18n from 'i18next'
import { getCookie } from './libraries/cookie'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import russianDataSet from 'libraries/locales/ru.json'
import englishDataSet from 'libraries/locales/en.json'
import armenianDataSet from 'libraries/locales/am.json'

i18n
  .use(LanguageDetector) // detect user language , learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init({
    //@TODO change it after we will have data in english
    lng: (getCookie('i18next') as string) || 'am',
    resources: {
      am: {
        translation: armenianDataSet,
      },
      ru: {
        translation: russianDataSet,
      },
      en: {
        translation: englishDataSet,
      },
    },
  })

export default i18n
