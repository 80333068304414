import { Fragment, type FC, useCallback } from 'react'
import emailSender from '@emailjs/browser'
import { Col, Form, Input, Row } from 'antd'

import Text from '../../components/Text'
import ImageHeading from '../../components/ImageHeading'

import styles from './ContactUs.module.scss'

const inputRules = [{ required: true, message: 'Please fill this input' }]
const armenianPhoneNumberPattern = /^(?:(\+374)[\s-]?)?(\(?\d{2}\)?[\s-]?)?\d{6}$/

const ContactUs: FC = () => {
  const [form] = Form.useForm()

  const onFinish = useCallback(
    async (values: any) => {
      emailSender
        .send('service_og16agw', 'template_h0t4mtb', values, {
          publicKey: 'TGRO_mKpHjfdLalb8',
        })
        .then()

      form.resetFields()
    },
    [form]
  )

  const onFinishFailed = async () => {
    form.validateFields().catch(e => {
      // eslint-disable-next-line no-console
      console.log(e)
    })
  }

  return (
    <Fragment>
      <ImageHeading
        title={'contactUs'}
        subTitle={'Կապվեք մեզ հետ՝ ցանկացած հարցի դեպքում'}
        image={'https://proeng.am/assets/contactUs.png'}
        description={
          'Մեր թիմը միշտ պատրաստ է օգնել ձեզ: Անկախ նրանից, թե ինչ հարց ունեք, մենք այստեղ ենք՝ աջակցելու և տրամադրելու անհրաժեշտ տեղեկությունները:'
        }
      />
      <div className={'container'}>
        <div className={styles.wrapper}>
          <Text text={'Կապ հաստատեք'} className={styles.wrapper__title} />
          <Text text={'Ուղարկեք մեզ հաղորդագրություն'} className={styles.wrapper__subtitle} />
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Row align={'top'} justify={'start'}>
              <Col xs={24} sm={10} xl={6}>
                <label>
                  <Text text={'Անուն*'} />
                  <Form.Item name={'name'} rules={inputRules}>
                    <Input size={'large'} />
                  </Form.Item>
                </label>

                <label>
                  <Text text={'Էլ-փոստ*'} />
                  <Form.Item name={'email'} rules={inputRules}>
                    <Input size={'large'} />
                  </Form.Item>
                </label>

                <label>
                  <Text text={'Հեռախոսահամար*'} />
                  <Form.Item
                    name={'phone'}
                    rules={[
                      { pattern: armenianPhoneNumberPattern, message: 'Please input correct phone number' },
                      ...inputRules,
                    ]}
                  >
                    <Input size={'large'} />
                  </Form.Item>
                </label>
              </Col>
              <Col xs={0} sm={2} md={2} />
              <Col xs={24} sm={10} flex={1}>
                <label>
                  <Text text={'Թեմա*'} />
                  <Form.Item name={'subject'} rules={inputRules}>
                    <Input size={'large'} />
                  </Form.Item>
                </label>
                <label>
                  <Text text={'Հաղորդագրություն*'} />
                  <Form.Item name={'message'} rules={inputRules}>
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </label>
              </Col>
            </Row>
          </Form>

          <button className={styles.wrapper__button} onClick={form.submit}>
            <Text text={'Ուղարկել'} />
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default ContactUs
