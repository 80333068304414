import type { FC } from 'react'

import { Carousel, Commitment, Services, AboutUs, Activity } from 'components'

const Home: FC = () => (
  <>
    <Carousel />
    <Services />
    <AboutUs />
    <Activity />
    <Commitment />
  </>
)

export default Home
