import { type FC, useState } from 'react'

import { Text } from 'components'

import type { TServicesCardProps } from './types'
import styles from './ServicesCard.module.scss'

const ServicesCard: FC<TServicesCardProps> = ({ name, Icon, HoverIcon }) => {
  const [isHover, setIsHover] = useState(false)

  const onMouseToggler = (status: boolean) => {
    setIsHover(status)
  }

  return (
    <div className={styles.card} onMouseOver={() => onMouseToggler(true)} onMouseLeave={() => onMouseToggler(false)}>
      {isHover ? <HoverIcon className={styles.card__icon} /> : <Icon className={styles.card__icon} />}
      <Text className={styles.card__name} text={name} />
      {/*<Text className={styles.card__description} text={description} />*/}
    </div>
  )
}

export default ServicesCard
